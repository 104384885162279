<template>
  <div class="bill-page">
    <div class="bill-row">
      <div class="bill-row-main">
        <h3 class="title">共享账本功能介绍：</h3>
        <div class="desc-content">
          <p class="desc">1. 共享账本适用于家庭、情侣、店铺员工等，可实现多人共同记账。</p>
          <p class="desc">2. 共享成员可彼此看到对方记账数据，但不能修改对方数据，让记录开销、核对业绩等更方便、快捷。</p>
        </div>
      </div>
      <div class="bill-row-main bill-step">
        <h3 class="title">功能介绍：</h3>
        <h4 class="step-title">第1步：新建共享账本</h4>
        <div class="step-content">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220412145840906-1.png" alt="">
        </div>
      </div>
      <div class="bill-row-main bill-step">
        <h4 class="step-title">第2步：点击成员缩略图进入成员管理</h4>
        <div class="step-content">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220412145854866-2.png" alt="">
        </div>
      </div>
      <div class="bill-row-main bill-step">
        <h4 class="step-title">第3步：点击添加成员</h4>
        <div class="step-content">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/2022041214590717-3.png" alt="">
        </div>
      </div>
      <div class="bill-row-main bill-step">
        <h4 class="step-title">第4步：通过微信/暗号邀请成员</h4>
        <div class="step-content step-content-details">
         <p class="details"><span class="bold">微信邀请：</span>点击微信添加成员，自动跳转微信</p>
         <p class="details">
           <span class="bold">暗号邀请：</span>
            点击暗号添加成员，输入专属暗号，暗号可告知或发送给想邀请的成员。
          </p>
        </div>
        <div class="step-content">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220412145924496-4.png" alt="">
        </div>
      </div>
      <div class="bill-row-main bill-step">
        <h4 class="step-title">第5步：通过微信/暗号加入账本</h4>
        <div class="step-content step-content-details">
          <p class="details">
            <span class="bold">微信邀请：</span>
            被邀请人点击邀请链接打开小程序，若使用APP记账，确保已绑定微信。
          </p>
         <p class="details">
           <span class="bold">暗号邀请：</span>
            被邀请人在账本也右上角点击【添加-加入共享账本】输入暗号即可添加成员。。
          </p>
        </div>
        <div class="step-content">
          <div class="images">
            <div class="img">
              <img
                src="//download-cos.yofish.com/youyu-gongjushiyebu/20220412145938535-5.png" alt="">
            </div>
            <div class="img">
              <img
                src="//download-cos.yofish.com/youyu-gongjushiyebu/20220412145952146-6.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Bill',
  created() {
    document.title = '共享账本使用说明';
  },
};
</script>
<style scoped lang="scss">
  .bill-page {
    width: 100%;
    min-height: 100%;
    background-color: #F6F6F6;
    overflow: hidden;
    font-family: PingFangSC-Medium, PingFang SC;
    padding-bottom: 126px;
  }
  .bill-row {
    margin: 0 25px 0 27px;
  }
  .bill-row-main {
    margin-top: 60px;
    &:first-child {
      margin-top: 35px;
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
    }
    .desc-content {
      margin-top: 19px;
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
    }
  }
  .step-title {
    margin-top: 19px;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
  }
  .step-content {
    margin-top: 20px;
    &.step-content-details {
      margin-top: 16px;
      .details {
        font-size: 26px;
        font-weight: 500;
        color: #333333;
        line-height: 50px;
      }
    }
    img {
      width: 100%;
    }
    .images {
      display: flex;
      .img {
        flex: 1;
        &:first-child {
          margin-right: 18px;
        }
      }
    }
  }
</style>
